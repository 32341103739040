<template>
  <spinner-container :loading="localLoading">
    <back-btn />
    <v-container fluid>
      <v-row justify="center">
        <v-col lg="8">
          <v-card class="card--shadow">
            <he-form :heData="heEachData" @emitData="updateHe" />
            <feedback
              :authorId="admin.id"
              :feedbacks="feedbacks"
              @createComment="createComment"
              @deleteComment="deleteComment"
            />
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </spinner-container>
</template>

<script>
import HeForm from "@/components/HE/HeForm";
import Feedback from "@/components/feedback/Feedback";
import { mapState } from "vuex";
import SpinnerContainer from "@/components/container/SpinnerContainer";
import BackBtn from "@/components/navigation/BackBtn";

export default {
  components: {
    HeForm,
    SpinnerContainer,
    BackBtn,
    Feedback,
  },
  data: () => ({}),
  computed: {
    ...mapState("HE", ["heEachData"]),
    ...mapState("Loading", ["localLoading"]),
    ...mapState("Feedback", ["feedbacks"]),
    ...mapState("Admin", ["admin"]),
  },
  methods: {
    createComment(comment) {
      const newComment = {
        item_id: this.$route.params.id,
        item_type: "HE",
        author_id: this.admin.id,
        author_type: "PA",
        body: comment,
      };
      this.$store.dispatch("Feedback/create", newComment);
    },
    deleteComment(id) {
      this.$store.dispatch("Feedback/delete", id);
    },
    updateHe(data) {
      this.$store.dispatch("HE/update", data).then(() => {
        this.$router.push(
          `/he/activity/${this.$route.params.month}/${this.$route.params.year}`
        );
      });
    },
  },
  created() {
    this.$store.dispatch("Loading/changeLocalLoading");
    this.$store.dispatch("Feedback/getAll", {
      item_id: this.$route.params.id,
      item_type: "HE",
    });
    this.$store.dispatch("HE/getEach", this.$route.params.id).then(() => {
      this.$store.dispatch("Loading/changeLocalLoading");
    });
  },
};
</script>
